<template>
  <div class="custom-alert">
    <span class="close-button" @click="closeAlert">x</span>
    <p>{{ content }}</p>
  </div>
</template>

<script>
export default {
  name: "CustomAlert",
  props: {
    type: String,
    content: String
  },
  methods: {
    closeAlert: function() {
      this.$el.style.display = "none";
    }
  },
  mounted: function() {
    if (this.type === "error") this.$el.style.backgroundColor = "#8C241A";
    else if (this.type === "warning") this.$el.style.backgroundColor = "orange";
    else if (this.type === "success") this.$el.style.backgroundColor = "green";
    else this.$el.style.backgroundColor = "gray";
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.custom-alert {
  background-color: #111;
  color: #fff;
  padding: 20px;
  border: 2px solid #ccc;
  z-index: 1;
}

.close-button {
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  float: right;
  margin-left: 15px;

  &:hover {
    color: #111;
  }
}
</style>

<template>
  <div class="the-login">
    <header class="login-header">
      <LogoPanel :link="true" height="130px" />
      <h2>Welcome to CharlieBot</h2>
      <h6>{{ tmsLoginMessage }}</h6>
    </header>
    <form class="login-form">
      <div v-if="!isAuth">
        <label>Username</label>
        <input
          v-model="username"
          class="login-input"
          type="text"
          name="username"
          placeholder="Username"
          required
        />
        <label>Password</label>
        <input
          v-model="password"
          class="login-input"
          type="password"
          name="password"
          placeholder="Password"
          required
        />
        <button class="login-button" type="submit" @click="login($event)">
          login
        </button>
        <label class="login-response"></label>
      </div>
      <div v-else>
        <button class="login-button" type="submit" @click="logout($event)">
          logout
        </button>
        <button
          class="login-button"
          @click="
            $event.preventDefault();
            startApp();
          "
        >
          go charlie
        </button>
        <label>You are already logged in</label>
      </div>
    </form>
  </div>
</template>

<script>
import tmsConfig from "@/utils/tmsConfig";
import LogoPanel from "@/components/LogoPanel";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TheLogin",
  components: {
    LogoPanel
  },
  data: function() {
    return {
      tmsLogo: tmsConfig.tmsLogo,
      tmsLink: tmsConfig.tmsLink,
      tmsLoginMessage: tmsConfig.tmsLoginMessage,
      username: null,
      password: null
    };
  },
  computed: {
    ...mapGetters("user", ["isAuth"])
  },
  methods: {
    ...mapActions("user", ["authRequest", "logoutUser"]),
    startApp: function() {
      this.$router.push({ name: "emailList", params: { type: "ner" } });
    },
    login: async function(event) {
      event.preventDefault();
      const loginResponse = this.$el.querySelector(".login-response");
      try {
        const formData = new FormData();
        formData.append("username", this.username);
        formData.append("password", this.password);
        await this.authRequest(formData);
        loginResponse.innerHTML = "Logged In Successfully";
        loginResponse.style.color = "green";
        this.startApp();
      } catch (err) {
        console.error(err);
        const errorCode = err.response ? err.response.status : 500;
        let errorMessage = err.response
          ? err.response.statusText
          : "Internal Server Error";
        if (errorCode === 400) errorMessage = err.response.data.detail;
        if (errorCode === 422) errorMessage = "Required fields empty";
        loginResponse.innerHTML = errorMessage;
        loginResponse.style.color = "red";
      }
    },
    logout: function(event) {
      event.preventDefault();
      this.logoutUser();
      // window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.the-login {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
}

.login-header {
  color: var(--sidebar-bg-color);
  margin-bottom: 10px;
}

.login-picture {
  position: relative;
  margin: 10px auto;
  display: block;
  height: 100px;
  width: 100%;
  border-radius: 50%;
}

.logo {
  padding: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}

.logo-panel {
  margin: 1em 0;
}

.login-form {
  background-color: #fff;
  color: #696153;
  text-align: left;
  padding: 10px 0;
  border-radius: 5px;
}

label {
  padding: 0 6px;
}

.login-input {
  width: 100%;
  margin: 10px 0;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.login-button {
  color: #fff;
  background-color: #8b7667;
  padding: 8px 16px;
  border: none;
  outline: none;
  margin: 10px 10px 10px 0;
  transition: background-color 250ms ease-in-out;
  text-transform: capitalize;
  border-radius: 3px;

  &:hover {
    background-color: #a39082;
  }
}
</style>

<template>
  <div id="login">
    <CustomAlert
      v-if="noAuth"
      type="error"
      content="Please login to access to Charlie's website"
    />
    <TheLogin />
  </div>
</template>

<script>
import tmsConfig from "@/utils/tmsConfig";
import CustomAlert from "@/components/CustomAlert";
import TheLogin from "@/components/TheLogin";
import { mapState } from "vuex";

export default {
  name: "LoginPage",
  props: {
    noAuth: Boolean
  },
  components: {
    CustomAlert,
    TheLogin
  },
  computed: {
    ...mapState("user", ["auth"]),
    loginElement: function() {
      return this.$el.querySelector(".the-login");
    }
  },
  data: function() {
    return {
      tms: tmsConfig.tmsName,
      tmsLoginMessage: tmsConfig.tmsLoginMessage
    };
  },
  methods: {
    fadeLogin: function() {
      this.loginElement.classList.add("is-visible");
    }
  },
  mounted: function() {
    setTimeout(() => this.fadeLogin());
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#login {
  background-image: var(--app-home-bg-image);
  background-size: cover;
  color: var(--app-home-color);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.the-login {
  opacity: 0;
  width: 40%;
  transition: opacity 750ms ease-in-out;
}

.is-visible {
  opacity: 1;
}

/* MEDIA QUERIES: RESPONSIVE DESIGN */
@media (max-width: 767px) {
  .the-login {
    width: 80%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .the-login {
    width: 50%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .the-login {
    width: 60%;
  }
}

@media (min-width: 1280px) and (max-width: 1799px) {
  .the-login {
    width: 40%;
  }
}

@media (min-width: 1800px) and (max-width: 1919px) {
  .the-login {
    width: 30%;
  }
}

@media (min-width: 1920px) {
  .the-login {
    width: 30%;
  }
}
</style>
